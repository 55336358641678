import React from "react";

export const Experience = () => {
  return (
    <div className="experience parag-mg parag-pd" id="experience">
      <h2>WORK EXPERIENCE</h2>
      <div className="work-timeline disp-flex">
        <div className="company-name">IT company(Japan)</div>
        <div className="exp-right-side disp-block">
          <div className="work-date">April 2016 - Present</div>
          <div className="experience-list">
            <ul>
              <li>
                In a project developing web version of a mobile application was
                responsible for implementing web socket, tree view and
                contributing to setting up the backend using TypeScript,
                Angular, Node.js and Express.js. Contributed to setting up the
                backend servers and infrastructures, as well as setting up
                security certification and development environment.
              </li>
              <li>
                Put effort to bring know-how to boost the team’s productivity
                and introduced the use of LiveShare to the team. It increased
                the pair programming and code reviews efficiency by 20%.
              </li>
              <li>
                Was responsible for the source code refactoring, resulting in
                the loading and rendering time being reduced by 90%.
              </li>
              <li>
                Collaborated with the team building e-commerce web applications,
                by designing and developing new pages, image upload feature and
                contributing to string-to-QR code conversion feature using Java
                and ebisumart. Despite being new to the team and the stack, met
                all the deadlines and took over other teammates’ tasks, which
                resulted in a successful launch of the application.
              </li>
              <li>
                Led a team of 3 engineers developing custom components in C# for
                Windows applications, resulting in decrease of estimate
                development time for future projects by 10 man-days.
              </li>
              <li>Gained a strong ability to adapt and pick up new skills.</li>
              <li>Trained and mentored junior engineers.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
