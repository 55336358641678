import React from "react";

export const About = () => {
  return (
    <div className="about parag-mg parag-pd" id="about">
      <h2>Hi, I'm Ksenia</h2>
      <h3>Software Developer</h3>
      <p>
        Throughout my carreer I got to be a part of a variety of projects and
        had a chance to learn and use different tools and programming languages.
        Getting to be a part of a full-stack project using Angular and Node.js
        became a starting point of my passion in front-end and web development
        in general.
      </p>
      <p>
        In my spare time I started learning and trying to design and build
        things on my own - that's when I really got hooked on React(pun
        intended).
      </p>
    </div>
  );
};
