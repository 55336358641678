import React from "react";

export const Skills = () => {
  return (
    <div className="skills parag-mg parag-pd" id="skills">
      <h2>SKILLS</h2>
      <div className="skills-list">
        <div>
          <h4>Languages</h4>
          <ul>
            <li>TypeScript</li>
            <li>JavaScript</li>
            <li>HTML</li>
            <li>CSS</li>
            <li>Java</li>
            <li>VB.NET</li>
            <li>C#</li>
            <li>SQL</li>
          </ul>
        </div>
        <div>
          <h4>Frameworks & Libraries</h4>
          <ul>
            <li>React</li>
            <li>Angular</li>
            <li>Node.js</li>
            <li>.NET</li>
          </ul>
        </div>
        <div>
          <h4>Tools & Platforms</h4>
          <ul>
            <li>Figma</li>
            <li>WordPress</li>
            <li>Git</li>
            <li>GitHub</li>
            <li>Netlify</li>
            <li>Firebase</li>
            <li>Supabase</li>
          </ul>
        </div>
        <div>
          <h4>Certifications</h4>
          <ul>
            <li>Oracle Certified Java Programmer, Silver SE 8</li>
            <li>Oracle Certified Java Programmer, Bronze SE 7/8 </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
