import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import Components
import { Header } from "./Header";
import { About } from "./About";
import { Experience } from "./Experience";
import { Skills } from "./Skills";
import { Projects } from "./Projects";
import { Contact } from "./Contact";
import { ContactForm } from "./ContactForm";
import { Footer } from "./Footer";

export const Home = () => {
  return (
    <div>
      <Router>
        <Header />
        <div className="content-wrapper">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <About />
                  <Experience />
                  <Skills />
                  <Projects />
                  <Contact />
                </>
              }
            />
            <Route path="/contact" element={<ContactForm />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
};
