import React from "react";
import { useNavigate } from "react-router-dom";

export const Contact = () => {
  // useNavigate
  let navigate = useNavigate();

  return (
    <div className="contact parag-mg parag-pd">
      <h2>CONTACT</h2>
      <div className="contact-area">
        <div className="text-align-cntr disp-block">
          Feel free to contact me anytime if you have any questions or want to
          chat about a project request
        </div>
        <div
          className="contact-btn text-align-cntr hover-cursor"
          onClick={() => navigate("/contact")}
        >
          GET IN TOUCH
        </div>
      </div>
    </div>
  );
};
