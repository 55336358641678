import React from 'react';
import './style/App.css';

// import Components
import { Home } from "./components/Home";

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;