import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../img/portflogo.svg";

export const Header = () => {
  // useState
  const [visible, setVisible] = useState("");
  const [active, setActive] = useState("");

  // useNavigate
  let navigate = useNavigate();
  const handleScrollTo = (path: string) => {
    setActive("");
    setVisible("");

    if (path.startsWith("/")) {
      navigate(path);

      return;
    }

    try {
      const anchor = document.querySelector(path) as HTMLElement;
      anchor.scrollIntoView({
        behavior: "smooth",
      });
    } catch {
      // TODO: scroll to the element when going back to the home page
      navigate("/" + path);
    }
  };

  // handlers
  const handleHamburgerOnClick = () => {
    if (active === "active") {
      setActive("");
      setVisible("");
    } else {
      setActive("active");
      setVisible("visible");
    }
  };

  return (
    <header>
      <nav className={`topnav topnav-right disp-flex ${visible}`}>
        <div
          className="logo hover-cursor"
          onClick={() => handleScrollTo("#about")}
        >
          <img src={logo} alt="Ksenia Codes" />
        </div>
        <ul>
          <li className="hover-cursor" onClick={() => handleScrollTo("#about")}>
            About
          </li>
          <li
            className="hover-cursor"
            onClick={() => handleScrollTo("#experience")}
          >
            Experience
          </li>
          <li
            className="hover-cursor"
            onClick={() => handleScrollTo("#skills")}
          >
            Skills
          </li>
          <li
            className="hover-cursor"
            onClick={() => handleScrollTo("#projects")}
          >
            Projects
          </li>
          <li
            className="hover-cursor"
            onClick={() => handleScrollTo("/contact")}
          >
            Contact
          </li>
        </ul>
        <div
          className={`hover-cursor topnav-hamburger ${active}`}
          onClick={handleHamburgerOnClick}
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </header>
  );
};
