import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";

import pomodoro from "../img/pj-pomodoro.png";
import yokohamaMusala from "../img/pj-yokohama-musala-hp.png";

export const Projects = () => {
  return (
    <div className="projects-container parag-mg parag-pd" id="projects">
      <h2>PERSONAL PROJECTS</h2>
      <div className="projects disp-flex">
        <div className="project-example disp-flex">
          <img src={pomodoro} alt="pomodoro" />
          <div className="project-description disp-flex">
            <h3>Pomotimer</h3>
            <div>
              A Pomodoro timer with custom settings, alarms and tasks features
            </div>
            <div>
              <ul>
                <li>React</li>
                <li>Typescript</li>
                <li>Firebase</li>
              </ul>
            </div>
            <div className="github-link disp-flex">
              <a
                href="https://github.com/ksenia-codes/pomodoro-timer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GitHubIcon /> Github
              </a>
              <a
                href="https://ksenia-codes-pomotimer.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit page
              </a>
            </div>
          </div>
        </div>
        <div className="project-example disp-flex row-reverse">
          <img src={yokohamaMusala} alt="Yokohama Musala HP" />
          <div className="project-description disp-flex">
            <h3>Yokohama Musala Home Page</h3>
            <div>A home page for a local prayer room.</div>
            <div>
              <ul>
                <li>React</li>
                <li>Typescript</li>
                <li>Firebase</li>
              </ul>
            </div>
            <div className="github-link disp-flex">
              <a
                href="https://github.com/ksenia-codes/yokohama-musala-homepage"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GitHubIcon /> Github
              </a>
              <a
                href="https://yokohamamusalla.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit page
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
