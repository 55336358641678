import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const ContactForm = () => {
  // useState
  const [status, setStatus] = useState("Submit");
  const [showMessageSent, setShowMessageSent] = useState(false);

  // useNavigate
  let navigate = useNavigate();

  // handlers
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setStatus("sending...");

    const target = e.target as typeof e.target & {
      name: { value: string };
      email: { value: string };
      message: { value: string };
    };
    let details = {
      name: target.name.value,
      email: target.email.value,
      message: target.message.value,
    };

    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });

    let result = await response.json();

    if (result.status === "Message Sent") {
      setShowMessageSent(true);
    }
  };

  const onClickMessageSent = () => {
    setShowMessageSent(false);
    setStatus("Submit");
    navigate("/");
  };

  return (
    <div className="contact-form-wrapper parag-mg parag-pd">
      <div className={`modal-win ${status === "Submit" ? "" : "visible"}`}>
        <div className={`message-sent-win ${showMessageSent ? "visible" : ""}`}>
          <div>You message has been sent.</div>
          <button
            className="disp-flex hover-cursor"
            onClick={onClickMessageSent}
          >
            OK
          </button>
        </div>
        <div
          className={`message-sent-win ${
            status !== "Submit" && !showMessageSent ? "visible" : ""
          }`}
        >
          <div>{status}</div>
        </div>
      </div>
      <h2>Get in touch</h2>
      <div className="">Have a question or a project request? Hit me up!</div>

      <form
        action=""
        method="post"
        className="contact-form"
        onSubmit={handleSubmit}
      >
        <div className="disp-flex contact-input-wrapper">
          <input
            className=""
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            required
          />

          <input
            className=""
            type="email"
            name="email"
            id="email"
            placeholder="E-mail"
            required
          />
        </div>

        <textarea
          className="disp-block"
          name="message"
          id="message"
          cols={50}
          rows={10}
          placeholder="Your message"
          required
        ></textarea>

        <div className="disp-flex contact-btn-wrapper">
          <button className="contact-btn hover-cursor" type="submit">
            SEND IT
          </button>
        </div>
      </form>
    </div>
  );
};
